.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;600&family=Righteous&family=Roboto:wght@300&display=swap');

header{
  position: fixed;
  z-index: 1000;
}

.header-div{
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.header-no-blur{
  display: flex;
  justify-content: space-between;
}


.title{
  font-family: Spacearella;
  color: black;
  font-size: 50px;
}

.logo-section-header{
  margin-left:50px;
  margin-top:20px;
  display: flex;
  cursor: pointer;
}

.logo-section-header img{
  height: 70px;
}


.logo-section-header p {
  font-family: Spacearella;
  line-height: 70px;
  margin: 0px 20px;
  font-size: 37px;
}



.links-section-header{
display: flex;
margin-right: 50px;
margin-top:10px;
}

.icon-links-header{
  display: flex;
  justify-content: space-around;
  width: 150px;
  margin-right: 40px;
}
.icon-links-header img{
  margin-top: 30px;
  height: 20px;
  cursor: pointer;
}

.header-open-menu{
  display: flex;
  flex-direction: column;
}

.header-open-menu img{
  height: 50px;
  width: 50px;
  margin-top:10px;
  cursor: pointer;
}

.header-open-menu p{
  margin-top:0px;
  margin-bottom:0px;
  text-align: center;
  font-family: 'Commissioner';
  opacity: 1;
  transition: 1s;
}

@media (max-width: 600px) {
  .header-overflow{
    position: fixed;
    height: 200px;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }
  .header-filter{
    z-index: 2;
    width: 150%;
    margin-left:-25%;
    margin-right: -25%;
    margin-top:-25px;
    height: 125px;
    background-color: #FFFFFF;
    filter: blur(15px);
  }
  .logo-section-header{
    margin-left:20px;
    margin-top:20px;
    display: flex;
    z-index: 2;

  }
  
  .logo-section-header img{
    height: 50px;
  }
  
  .logo-section-header p {
    font-family: Spacearella;
    line-height: 55px;
    margin: 0px 10px;
    font-size: 20px;
  }
  .links-section-header{
    display: flex;
    margin-right: 20px;
    margin-top:10px;
    z-index: 2;

    }
    
    .icon-links-header{
      display: none;
    }
    .icon-links-header img{
      margin-top: 30px;
      height: 20px;
    }
    
    .header-open-menu{
      display: flex;
      flex-direction: column;
    }
    
    .header-open-menu img{
      height: 45px;
      width: 45px;
      margin-top:12.5px;
      cursor: pointer;
    }
    
    .header-open-menu p{
      margin-top:0px;
      margin-bottom:0px;
      text-align: center;
      font-family: 'Commissioner';
      opacity: 1;
      transition: 1s;
      display: none;
    }

}

.top-section{
  padding-top: 10vh;
  height: 90vh;
  width: 100vw;
}

.blur-block {
  height: 18px;

  background: rgba(0, 0, 0, 0.3);
  filter: blur(20px);
  width: 100vw;
  z-index: 1001;


}

@keyframes mainPhotoMove {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}

.top-section-main-image{
  height: 80vh;
  animation: mainPhotoMove 2s infinite;
}
.top-section-pigeimgns{
  display: flex;
}
.top-section-main-text{
  display: flex;
  flex-direction: column;
}

.mobile-main-image{
  display: none;
}
@media (max-width: 600px){
  .top-section-main-text{
    display: none;
  }
  .top-section-main-image{
    display: none;
  }
  .mobile-main-image{
    display: block;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top:20%;
  }
}
.o-image{
  height: 100px;
  box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 314.5px;
  margin-right: -10px;
}

.top-section-head-text{
  margin-top:25vh;
}

.top-section-head-text p{
  font-family: Spacearella;
  font-size:124px;
  margin-top:0px;
  margin-bottom:0px;
  line-height: 110px;
  text-align: left;
}

.top-sectino-sub-text{
  font-family: 'Righteous';
  font-size: 48px;
}
.top-section-flex{
  display: flex;
  justify-content: center;
}

@keyframes arrowMove{
  0%{
    margin-top:10px;
  }
  50%{
    margin-top:20px;
  }
  100%{
    margin-top:10px;
  }
}
.arrow-anchor{
  position: absolute;
  left:45%;
  right:45%;
  width: 10%;
  height: 50px;
  margin-top:50px;
  animation: arrowMove 2s infinite;
  cursor: pointer;
}

@media (max-width:600px) {
  .arrow-anchor-section{
    height: 200px;
    width: 100%;
    display: none;
  }
}
.arrow-anchor img{
  margin-top:10px;
  z-index: 5;

}

.collection-section h3{
  font-family: 'Righteous';
  text-align: center;
  font-size: 48px;
  margin-bottom:0px;
}

.collection-section p{
  width: 560px;
  text-align: center;
  margin-left:auto;
  margin-right: auto;
  font-family: 'Commissioner';
  font-size: 22px;
  margin-bottom:0px;
}

@media (max-width:600px){

.collection-section h3{
  font-family: 'Righteous';
  text-align: center;
  font-size: 48px;
  margin-bottom:0px;
  margin-left:50px;
  margin-right:50px;
}

.collection-section p{
  /* max-width: 70%; */
  width: 80%;
  text-align: center;
  margin-left:50px;
  margin-right:50px;
  font-family: 'Commissioner';
  font-size: 22px;
  margin-bottom:0px;
  
}
}

.purpose-section{
  display: flex;
  justify-content: center;
  margin-top:150px;
}

.purpose-text p{
  font-family: 'Commissioner';
  width: 400px;
  font-size: 24px;
}
.purpose-text h3{
  font-family: 'Righteous';
  font-size: 48px;
  margin-bottom:0px;
}



.purpose-photo{
  height: 400px;
  width: 400px;
  /* border: 3px solid black; */
  margin-top:120px;
  margin-left:100px;
  border-radius: 200px;
}

@media (max-width: 600px){
  .purpose-text p{
    font-family: 'Commissioner';
    width: 80%;
    margin-left:10%;
    margin-right:10%;
    margin-top:50px;
    font-size: 24px;
  }
  .purpose-section{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top:150px;
    width: 100%;
  }
  .purpose-photo{
    margin-left:10%;
    margin-right:10%;
    margin-top:30px;
    height: 300px;
    width: 300px;
    /* border: 3px solid black; */
    /* background-color: #C4C4C4; */
    border-radius: 1000px;
  }
  
}

.guaranteed h3{
  font-family: 'Righteous';
  text-align: center;
  font-size: 48px;
  margin-bottom:0px;
}

.guaranteed p{
  max-width: 1000px;
  text-align: center;
  margin-left:auto;
  margin-right: auto;
  font-family: 'Commissioner';
  font-size: 22px;
  margin-bottom:0px;
}

@media (max-width: 600px){
  .guaranteed p{
    max-width: 80%;
    margin-left:10%;
    margin-right:10%;

    text-align: center;
    margin-left:auto;
    margin-right: auto;
    font-family: 'Commissioner';
    font-size: 22px;
    margin-bottom:0px;
  }
}

.team-section h3{
  font-family: 'Righteous';
  text-align: center;
  font-size: 48px;
  margin-bottom:0px;
}
.team-block{
  display: flex;
  justify-content: center;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  margin-top:50px;
  flex-wrap: wrap;
}

.team-item{
  flex: 1 0 21%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:50px;
}

.team-item-img{
  border-radius: 50%;
  background-color: #C4C4C4;
  border:3px solid black;
  height: 200px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;

}
.team-item h4{
  font-family: 'Commissioner';
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
  width: 200px;
  margin-bottom:0px;
  margin-left: auto;
  margin-right: auto;
}

.team-item p{
  font-family: 'Commissioner';
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  width: 200px;
  margin-top:10px;
  margin-left: auto;
  margin-right: auto;
}

footer {
  display: flex;
  justify-content: space-between;
  height: 100px;
  border-top:3px solid black;
  margin-left:100px;
  margin-right:100px;

}

.logo-footer {
  margin-top:20px;
  height: 60px;
  width: 60px;
}

.icon-links-footer{
  display: flex;
  justify-content: space-around;
  width: 150px;
  margin-right: 100px;
}
.icon-links-footer img{
  margin-top: 40px;
  height: 20px;
}

.footer-copyright{
  line-height: 20px;
  margin-top:40px;
  font-family: 'Commissioner';
}

.collection-slide-div{
  height: 300px;
  width: 300px !important;
  border: 3px solid black;
  border-radius: 20px;
  background-color: #C4C4C4;
}

@media (max-width: 600px){
  .collection-slide-div{
    height: 130px;
    width: 30vw !important;
    border: 3px solid black;
    border-radius: 20px;
    background-color: #C4C4C4;
  }
  .footer-copyright{
    display: none;
  }
  footer{
    width: 95%;
    margin-left:2.5%;
  }
  .icon-links-footer{
    margin-left:10%;
  }
}


.slider-container-collection{
  margin-top:50px;
}

.slider-container-guarantied{
  margin-top:50px;
  margin-bottom:50px;
}

.guarantied-slide-div{
  height: 400px;
  /* width: 300px !important; */
  border: 3px solid black;
  border-radius: 20px;
  background-color: #C4C4C4;
  margin-left:5%;
  margin-right: 5%;
  width: 600px;
  object-fit: cover;
}

@media (max-width: 600px){
  .guarantied-slide-div{
    height: 200px;
    /* width: 300px !important; */
    border: 3px solid black;
    border-radius: 20px;
    background-color: #C4C4C4;
    margin-left:5%;
    margin-right: 5%;
    width: 250px;
    object-fit: cover;
  }
}

.roadmap-line{
  border:2px solid black;
  height: 100%;

  width: 0px;
  margin-left: auto;
  margin-right: auto;


}

.div-roadmap{
  margin-top:50px;
  height: 300px;
  display: flex;
}

.roadmap-left{
  width: 49%;
}
.roadmap-right{
  width: 49%;
}
.roadmap-middle{
  width: 2%;
  height: 98%;
  margin-top:20px;
}

.section-roadmap {
  margin-top:100px;
}


.section-roadmap h3{
  font-family: 'Righteous';
  text-align: center;
  font-size: 48px;
  margin-bottom:0px;
}


.circle-roadmap{
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:3px;
  margin-top:3px;


}

.roadmap-elem-right h2{
  font-family: 'Righteous';
  font-size: 48px;
  margin-bottom:0px;
  text-align: left;
  margin-top:0px;
}

.roadmap-elem-right{
  margin-left:50px;
}

.roadmap-elem-right p{
  max-width: 400px;
  font-family: 'Commissioner';
  font-weight: 400;
  margin-top:0px;
  font-size: 20px;
}
@media (max-width: 600px){
  .roadmap-elem-right p{
    padding-right: 20px;
  }
}

.roadmap-checkpoint{
  height: 40px;
}



.roadmap-elem-left{
  margin-right:50px;
  float: right;
}

.roadmap-elem-left h2{
  font-family: 'Righteous';
  font-size: 48px;
  margin-bottom:0px;
  text-align: right;
  margin-top:0px;
}

.roadmap-elem-left p{
  max-width: 400px;
  font-family: 'Commissioner';
  text-align: right;
  font-weight: 400;
  margin-top:0px;
  font-size: 20px;
}

.menu-opened{
  position: fixed;
  width: 90vw;
  height: 80vh;
  background-color: white;
  left:5vw;
  top:13vh;
  filter: blur(20px);
  z-index: 100;
}



.menu-content{
  position: fixed;
  width: 90vw;
  height: 80vh;
  left:5vw;
  top:13vh;
  z-index: 101;
  display: flex;
}

.menu-content-links{
  width: 30%;
  z-index: 101;
  position: relative;
}

.navigation-links-group{
  /* margin-top:20vh; */
  /* width: 30%; */
  /* margin-left:30%; */
  /* text-align: left; */
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 250px;
  height: 350px;
  
}

.navigation-links-group h4{
  font-family: 'Righteous';
  font-size: 48px;
  margin-top:10px;
  margin-bottom: 10px;
  transition: 0.2s;
  cursor: pointer;
  margin-left:50%;
  
}

.navigation-links-group h4:hover {
  text-shadow: 0px 8px 4px rgba(44, 195, 240, 0.5);
}




.go-top-button{
  height: 120px;
  width: 120px;
  position: fixed;
  right: 5%;
  bottom: 5%;
  z-index: 1002;
  cursor: pointer;
}
.go-top-button img{
  height: 120px;
  width: 120px;
}


@media (max-width: 600px){
  .go-top-button{
    height: 120px;
    width: 120px;
    position: fixed;
    right: 0%;
    bottom: 0%;
    z-index: 1002;
    cursor: pointer;
  }
  .go-top-button img{
    height: 120px;
    width: 120px;
  }
}

.menu-content-mint{
  width: 50%;
  z-index: 101;
  position: relative;
}

.menu-content-socials{
  width: 20%;
  position: relative;

}

.pigeon-mint-block{
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  height: 250px;
}


.pigeon-mint-image{
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 50%;
  border:3px solid black;
  background-color: #C4C4C4;
}

.pigeon-mint-button{
  height: 40px;
  width: 200px;
  background-color: #2CC3F0;
  border-radius: 50px;
  border:3px solid black;
  margin-top:20px;
}


.pigeon-mint-button p{
  text-align: center;
  margin-top:0px;
  margin-bottom:0px;
  line-height: 40px;
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 20px;
}


.socials-block {
  position: absolute;
  height: 200px;
  display: flex;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  flex-direction: column;
}

.socials-block img {
  height: 35px;
  width: 35px;
  margin-bottom:10px;
  margin-top:10px;
  transition: 0.5s;
  cursor: pointer;
}
.socials-block img:hover{
  filter: drop-shadow(0px 4px 4px rgba(44, 195, 240, 0.6));
  transition: 0.5s;

}

@media (max-width: 600px){
  .menu-content{
    display: flex;
    flex-direction: column;
  }
  .menu-content-socials{
    width: 100%;
    height: 40%;
  }
  .menu-content-links{
    width: 100%;
    height: 60%;
  }
  /* .navigation-links-group {} */
  .navigation-links-group h4{
    text-align: center;
    width: 100%;
    margin-left:0%;
    font-family: 'Commissioner';
    font-weight: 500;
    font-size: 35px;
    margin-top:30px;
    margin-bottom:30px;
  }
 
  .menu-content-mint{
    display: none;
  }
  .socials-block{
    margin-top:30px;
    display: flex;
    flex-direction: row;
    gap:30px;
  }
}


.mint-now-mobile{
  height: 50px;
  width: 200px;
  background-color: #FFBA41;
  margin-left:auto;
  margin-right: auto;
  border-radius: 30px;
  border: 3px solid black;
  transition: 0.5s;
}
.mint-now-mobile p{
  line-height: 50px;
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 24px;
  margin-top:0px;
  margin-bottom:0px;
  text-align: center;
}

.mint-now-mobile:active {
  background-color: #e08e00;
}


@media (min-width:600px) {
  .mobileVersion{
    display: none;
  }  
}



@media (max-width:600px) {
  .pcVersion{
    display: none;
  }  
}





.write-us-section{
  width: 100vw;
  margin: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Righteous;
  font-size: 35px;
}


.write-us-section img{
  height: 400px;
  width: 690px;
}






@media (max-width: 600px){
  .write-us-section{
    font-family: Righteous;
    font-size: 35px;
    height: 60vh;
  }
  .write-us-section img{
    width: 292px;
    height: 164px;
    object-fit: cover;
  }
  
}




















.evdev-section {
  justify-content: center;
  display: flex;
  gap: 10px;
  color: black;
  font-family: Righteous;
  text-decoration: none;
}
.evdev-section img{
  margin-top:10px;
  height: 30px;
}